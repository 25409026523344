/* Panel Body, no padding on designer*/
.panel-custom-form_designer > .panel-body {
	/*padding: 0;*/
}

/* CSS used here will be applied after bootstrap.css */
.panel-heading-sm {
	padding: 4px 10px;
}

.panel-body-sm {
	padding: 5px 10px;
}

.modal.extra-width > .modal-dialog {
	width: 800px;
}

textarea#ckeditor_textarea {
	min-height: 400px;
}

.editable {
	position: relative;
}

.editable:hover .overlay {
	display: block;
}

.modal-header {
	padding: 10px 15px;
	cursor: move;
	background: $panel-default-heading-bg;
}

.editable .overlay {
	display: none;
	height: 100%;
	width: 100%;
	background-color: rgba(63, 141, 191, 0.9);
	position: absolute;
	left: 0px;
	top: 0px;
	cursor: default;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.editable .overlay i {
	border: 1px solid white;
	position: absolute;
	height: 30px;
	width: 30px;
	line-height: 30px;
	left: 50%;
	top: 50%;
	text-align: center;
	margin-top: -15px;
	margin-left: -15px;
	color: rgba(255, 255, 255, 1);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	font-size: 16px;
}

.btn[data-action="add-block"] {
	left: calc(50% - 50px);
	position: absolute;
	top: 50%;
	width: 100px;
	box-shadow: 0px 0px 5px 0px $gray-dark;
}

.panel-relative {
	position: relative;
}

.editable .overlay i:hover {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

.newsletter-block-preview {
	padding: 5px;
}

.newsletter-block-preview img {
	opacity: 0.5;
	max-width: 400px;
	max-height: 200px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.newsletter-block-preview:hover img {
	opacity: 1;
}

.newsletter-block-preview:hover .newsletter-block-preview-add {
	opacity: 1;
}

.is-duplicable {
	outline: 1px dashed #555;
}

.newsletter-block-preview a.btn[data-action="add-block"] {
	position: absolute;
	left: 45%;
	top: 50%;
	z-index: 1005;
	display: none;
}

.newsletter-block-preview:hover a.btn[data-action="add-block"] {
	display: block;
}

.ui-widget {
	font-size: 12px;
}

#newsletter-preloaded-rows {
	display: none;
}

#newsletter-preloaded-export {
	display: none;
}

#newsletter-preloaded-download {
	display: block;
	position: absolute;
	left: -10000px;
	top: -10000px;
}

#newsletter-builder {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-bottom: 15px;
}

#newsletter-builder-sidebar {
	float: none;
	width: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: #FFFFFF;
	overflow-y: auto;
	height: 60px;
	z-index: 1;
}

#newsletter-builder-sidebar-logo {
	height: 250px;
	width: 100%;
	float: left;
	text-align: center;
}

#newsletter-builder-sidebar-logo img {
	margin-top: 50px;
}

#newsletter-builder-sidebar-buttons {
	float: left;
	width: 100%;
	margin-top: 20px;
}

#newsletter-builder-sidebar-buttons-bbutton {
	height: 40px;
	width: 160px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	line-height: 40px;
	color: rgba(84, 101, 122, 1);
	font-weight: 600;
	font-size: 14px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
	cursor: pointer;
	margin-bottom: 25px;
	border: 1px solid #e4e4e5;
}

#newsletter-builder-sidebar-buttons-bbutton:hover {
	background-color: rgba(153, 153, 153, 0.1);
}

.newsletter-you-edit-label:before {
	clear: both;
	float: none;
	content: ' ';
}

.newsletter-you-edit-label {
	float: none;
	clear: both;
	padding: 5px 15px;
	border-top-left-radius: 10px;
	border-top-right-radius: 15px;
	border: 1px solid $panel-default-border;
	margin-top: 20px;
	display: inline-block;
	margin-left: 15px;
}

#newsletter-builder-area {
	margin-top: -1px;
	margin-bottom: 20px;
	border: 1px solid $panel-default-border;
	width: 900px;
	background: url(../../img/brillant.png);
	min-height: calc(100vh - 300px);
}

#newsletter-builder-area > .container {
	width: 800px;
	margin: auto;
	padding: 20px 0;
}

#newsletter-builder-area-center-frame-buttons {
	width: 100%;
	float: left;
	height: 30px;
}

#newsletter-builder-area-center-frame-buttons-dropdown {
	position: absolute;
	background-color: rgba(255, 255, 255, 1);
	z-index: 1;
	top: 40px;
	left: 0px;
	text-align: left;
	width: 300px;
	display: none;
	border: 1px solid #e7e7e7;
	border-radius: 2px;
	box-shadow: 0px 0px 5px 0px #DDD;
}

.newsletter-builder-area-center-frame-buttons-dropdown-tab {
	border-top: 1px solid #e7e7e7;
	display: block;
	padding: 5px;
	float: left;
	width: 100%;
	cursor: pointer;
}

.newsletter-builder-area-center-frame-buttons-dropdown-tab:after {
	display: block;
	clear: both;
	float: none;

}

.newsletter-builder-area-center-frame-buttons-dropdown-tab:first-child {
	border-top: none;
}

.newsletter-builder-area-center-frame-buttons-dropdown-tab:hover {
	background-color: rgba(153, 153, 153, 0.2);
}

.newsletter-builder-area-center-frame-buttons-content {
	border-radius: 2px;
	box-shadow: 0px 0px 5px 0px #DDD;
	border-top: 1px solid #e7e7e7;
	max-height: 500px;
	width: 420px;
	background-color: rgba(255, 255, 255, 1);
	position: absolute;
	left: 298px;
	margin-top: -2px;
	z-index: 1;
	cursor: default;
	overflow-y: scroll;
	padding-bottom: 50px;
	display: none;
}

.newsletter-builder-area-center-frame-buttons-content-tab {

	float: left;
	width: 400px;
	padding: 20px;
	margin-bottom: 10px;

	position: relative;
	border-bottom: 2px dashed #e7e7e7;
}

.newsletter-builder-area-center-frame-buttons-content-tab:hover img {
	opacity: 0.8;
}

.newsletter-builder-area-center-frame-buttons-content-tab:hover .newsletter-builder-area-center-frame-buttons-content-tab-add {
	opacity: 1;
}

.newsletter-builder-area-center-frame-buttons-content-tab img {
	display: block;
	width: 300px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	border: 1px solid #E7E7E7;
	padding: 5px;
	border-radius: 5px;
}

.newsletter-builder-area-center-frame-buttons-content-tab-add {

	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	opacity: 0;

}

#newsletter-builder-area-center-frame-buttons-reset {
	height: 30px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	line-height: 30px;
	color: rgba(84, 101, 122, 1);
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
	margin-bottom: 25px;
	font-size: 15px;
	float: right;
	background-color: rgba(255, 255, 255, 0.8);
}

#newsletter-builder-area-center-frame-buttons-reset a {
	padding-right: 15px;
	padding-left: 15px;
	display: block;
	text-decoration: none;
	color: rgba(84, 101, 122, 1);
}

#newsletter-builder-area-center-frame-buttons-reset span {
	font-size: 10px;
}

#newsletter-builder-area-center-frame-buttons-reset:hover {
	background-color: rgba(255, 255, 255, 0.3);
}

#newsletter-builder-area-center-frame-content {
	width: 800px;
	/*background-color: rgba(153,153,153,1);*/
}

#newsletter-builder-area-center-frame-content:empty:after {
	content: 'Newsletter Block hierher!';
	padding: 40px;
	text-align: center;
	display: block;
	border: 1px solid #cd0a0a;
	background: #FFCCBA;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 5px;

}

.sim-row {
	-webkit-transition: box-shadow 0.5s;
	-moz-transition: box-shadow 0.5s;
	-o-transition: box-shadow 0.5s;
	transition: box-shadow 0.5s;
	position: relative;
	border: 1px dashed #888;
	margin-top: -1px;

}

.sim-row:first-child {
	margin-top: 0px;
}

.sim-row-is-collapsed {
	max-height: 110px;
	overflow: hidden;

}

.sim-row:hover {
	cursor: move;
	border: 1px dashed $gray-dark;
}

.sim-row-collapse,
.sim-row-sortdown,
.sim-row-sortup,
.sim-row-delete {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background: #e7e7e7 none repeat scroll 0 0;
	border-bottom-right-radius: 5px;
	border-color: #888;
	border-image: none;
	border-style: solid solid solid none;
	border-top-right-radius: 5px;
	border-width: 1px 1px 1px 0;
	box-shadow: 0 0 5px 0 #ddd;
	color: #fff;
	font-size: 14px;
	height: 20px;
	position: absolute;
	right: -20px;
	text-align: center;

	text-shadow: 1px 1px 1px #888;
	top: 5px;
	transition: all 0.5s ease 0s;
	width: 20px;
	cursor: pointer;
	line-height: 15px;
}

table.borderless td, table.borderless th {
	border: none !important;
}

.sim-row-collapse {
	top: 80px;
}

.sim-row-sortdown {
	top: 55px;
}

.sim-row-sortup {
	top: 30px;
}

.sim-row-is-collapsed .sim-row-collapse,
.sim-row-is-collapsed .sim-row-sortdown,
.sim-row-is-collapsed .sim-row-sortup,
.sim-row-is-collapsed .sim-row-delete {
	left: 0;
	right: inherit;

}

.sim-row-sortdown i, .sim-row-sortup i, .sim-row-delete i {
	font-size: 14px;
	line-height: 16px;
}

.sim-row-delete:hover i {
	color: #cd0a0a;
}

.sim-row:hover .sim-row-delete {
	opacity: 1;
}

.sim-row-collapse:hover, .sim-row-sortdown:hover, .sim-row-sortup:hover, .sim-row-delete:hover {
	text-shadow: 1px 1px 1px #000;
}

.sim-row-edit {
	position: relative;
}

.sim-row-edit-hover {
	height: 100%;
	width: 100%;
	background-color: rgba(63, 141, 191, 0.9);
	position: absolute;
	left: 0px;
	top: 0px;
	cursor: default;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.sim-row-edit-hover i {
	border: 1px solid white;
	position: absolute;
	height: 30px;
	width: 30px;
	line-height: 30px;
	left: 50%;
	top: 50%;
	text-align: center;
	margin-top: -15px;
	margin-left: -15px;
	color: rgba(255, 255, 255, 1);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	font-size: 16px;
}

.sim-row-edit-hover i:hover {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

h4.template-title {
	font-size: 12px;
	display: inline-block;
	margin: 0;
	padding: 10px 5px;
	border: 1px solid #E7E7E7;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	border-bottom: 0;
	background: #f8f8f8;
	margin-left: 10px;
}

.masked * {
	opacity: 0.5;
}

.masked {
	background: #444;
}

option.option-hidden {
	display: none !important;
}

.newsletter-builder-area-center-frame-buttons-dropdown-tab.active {
	background: #E7e7e7;
}

li.dropdown-submenu > ul.dropdown-menu {
	max-height: 400px;
	overflow-y: auto;
	min-width: 440px;
}
