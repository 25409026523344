.query-builder {
	width: 100%;
}

.query-builder .rules-group-container {
	padding: 10px 10px 6px 10px !important;
	border: 1px solid #dddddd !important;
	background: #ffffff !important;
}

.query-builder .rule-container {
	background: #f5f5f5 !important;
}

.query-builder .has-error {
	background-color: #efbdbd !important;
	border-color: #a94442 !important;
}

.query-builder .rule-actions .btn-danger {
	margin: 6px 5px 0 0 !important;
}
