/* Icons */
@font-face {
	font-family: 'dbvfont';
	src: url('../fonts/dbv-font.eot');
	src: url('../fonts/dbv-font.eot') format('embedded-opentype'),
	url('../fonts/dbv-font.ttf') format('truetype'),
	url('../fonts/dbv-font.woff') format('woff'),
	url('../fonts/dbv-font.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

.df {
	position: relative;
	top: 2px;
	display: inline-block;
	font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} dbvfont; // shortening font declaration
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

.df-box-o:before {
	content: "\ea01";
}

.df-box:before {
	content: "\ea02";
}
