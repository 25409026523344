.datepicker.dropdown-menu {
  color: #333333;
}

.date-range-inline {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  .form-inputs {
    width: 200px;
  }

  li {
    list-style: none;
    float: left;

    .input-group-addon {
      font-size: 12px;
      line-height: 1.5;
      padding: 1px 5px;
    }

    input {
      padding: 1px 5px;
      font-size: 12px;
      line-height: 1.5;
      height: 24px;
    }

    &.btn-action {
      padding: 0px 0px 0px 5px;
    }
  }
}