// Basic Colors
$font-size-base:          14px !default;
$navy: #1ab394;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #23c6c8;      // Info color
$yellow: #f8ac59;     // Warrning color
$red: #ED5565;        // Danger color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badget
$label-badget-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;


// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: url('../img/patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profil-pattern: url("../img/patterns/header-profile.png") no-repeat;
$nav-text-color: #a7b1c2;

// Components
//
// Define common padding and border radius sizes and more.

$border-width:                1px !default;

// Bootstrap 4

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

//$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .9 !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

// Tooltips

$tooltip-font-size:                 $font-size-base*1.3 !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 .25rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:      3         .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                false !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

// Popovers

$popover-font-size:                 $font-size-lg !default;
$popover-bg:                        $white !default;
$popover-max-width:                 300px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              rgba($black, .2) !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;

$popover-header-bg:                 darken($popover-bg, 3%) !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          .75rem !default;

$popover-body-color:                $gray-darker !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;