/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.4
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "../vendor/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "variables";
@import "mixins";

@import "bootstrap";
@import "font-awesome";

// Toastr
@import "../vendor/toastr/toastr.scss";

// INSPINIA Theme Elements
@import "typography";
@import "navigation";
@import "top_navigation";
@import "buttons";
@import "badgets_labels";
@import "breadcrumb";
@import "elements";
@import "sidebar";
@import "base";
@import "pages";
@import "chat";
@import "metismenu";
@import "spinners";
@import "dbv-font";

// Partials
@import "partials/newsletter-designer";
@import "partials/query-builder";
@import "partials/forms";
@import "partials/tables";
@import "partials/pagination";
@import "partials/datepicker";

// Landing page styles
@import "landing";

// RTL Support
@import "rtl";

// For demo only - config box style
@import "theme-config";

// INSPINIA Skins
@import "skins";
@import "md-skin";

// Media query style
@import "media";

// Custom style
// Your custom style to override base style
@import "custom";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  #page-wrapper {
    margin: 0;
  }
}

.flash-container {
	display: none;
}

#modal-keybindings {
	.action-column {
		width: 60%;
	}

	.shortcut-column {
		width: 40%;
	}
}
