//
// Tables
// --------------------------------------------------

table .sortable a {
	color: $text-color;
}

table span.sortable a.sorting {
	color: $blue;
}
